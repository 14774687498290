import React from 'react'
import ResumeCard from './ResumeCard'
import { motion } from 'framer-motion';


const Achievements = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
    
    <div className='w-full flex justify-between gap-20 mt-10 sm:flex h-full'> 
        {/* part one */}
      <div className='lgl w-1/2'> 
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]"></p>
          <h2 className="text-3xl md:text-4xl font-bold text-center">University Project</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px]  border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Inventory Management Project"
            subTitle="Charles Sturt University"
            result="Credit Obtained"
            des="The goal of the project was to make a software to handle inventory for the warehouse. The
            software helps to add stocks, delete stock, search stock, update stock, generate report."
          />
          {/* <ResumeCard
            title="AS - Science & Information"
            subTitle="SuperKing College (2001 - 2005)"
            result="4.75/5"
            des="Higher education is tertiary education leading to award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="Secondary School Education"
            subTitle="Kingstar Secondary School (1998 - 2000)"
            result="5.00/5"
            des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          /> */}
        </div>
      </div>
      {/* part Two */}

      <div className='w-1/2'>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]"></p>
          <h2 className="text-3xl md:text-4xl font-bold text-center">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px]  border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Water Industry Project"
            subTitle="Adasa Systems"
            result="Sydney"
            des="Providing transparency to the clints in water industry via web portal public APIs."
          />
          {/* <ResumeCard
            title="Web Developer & Trainer"
            subTitle="Apple Developer Team - (2012 - 2016)"
            result="MALAYSIA"
            des="A popular destination with a growing number of highly qualified homegrown graduates, it's true that securing a role in Malaysia isn't easy."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="Nike - (2020 - 2011)"
            result="Oman"
            des="The Oman economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
          /> */}
        </div>
      </div>
      </div>
      </motion.div>
  )
}

export default Achievements