import React from 'react'
import {useTypewriter, Cursor} from 'react-simple-typewriter'
import { FaLinkedinIn, FaReact, FaNodeJs, } from 'react-icons/fa'
import{SiTailwindcss} from 'react-icons/si'
import { Link } from 'react-router-dom'


function LeftBanner() {

        const [text] = useTypewriter({
            words:['Professional Coder.','Full Stack Developer.', 'UI Designer.'],
            loop: false,
            typeSpeed: 20,
            deleteSpeed: 10,
            delaySpeed: 2000
        })
  return (
    <div className="w-full lgl:width-1/2  flex flex-col gap-20">
        <div className="flex flex-col gap-5">
          <h1 className="text-6xl font-bold text-black">
            Hi, I'm
            <span className="text-designColor capitalize"> Vishaldeep</span>
          </h1>
          <h2 className="text-4xl font-bold text-black"> a 
            <span className=''> {text}</span> <Cursor cursorColor='red'
            cursorBlinking='false' />            
        
            

          </h2>
          <p className="text-base font-bodyFont leading-6 tracking-wide mt-6">
          I specialise in complex web programming. I take pride in writing clean, performance-optimised code following
best practices of software design patterns and architectures. I emphasise functional and elegant software
design using the latest programming technologies and design skills.
          </p>
        </div>
        <div className="flex flex-col lgl:flex-row gap-10 justify-center">
        <div>
            <h2 className="text-base uppercase font-titleFont mb-4">Find me on</h2>
            <div className="flex gap-4">
                <Link to='https://www.linkedin.com/in/vishal333/'>
                <span className="bannerIcon"><FaLinkedinIn/></span>
                </Link>
            </div>
        </div>
        <div>
            <h2 className="text-base uppercase font-titleFont mb-4">Best Skills On</h2>
            <div className="flex gap-4">
                <span className="bannerIcon"><FaReact/></span>
                <span className="bannerIcon"><FaNodeJs/></span>
                <span className="bannerIcon"><SiTailwindcss/></span>
            </div>
        </div>
        
        
            
        </div>
      </div>
  )
}

export default LeftBanner