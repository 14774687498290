import React from 'react'
import ResumeCard from './ResumeCard'
import { motion } from 'framer-motion';



const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >

    <div className='w-full flex gap-20 mt-10 sm:flex-col h-[800px]'> 
        {/* part one */}
      <div className='' > 
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm  text-designColor tracking-[4px] text-center">Job Experience</p>
          <h2 className="text-3xl md:text-4xl font-bold text-center">Software Engineer</h2>
        </div>
        <div className="mt-6 w-full lgl:mt-14 lgl:w-1/2 lgl:ml-[400px] h-[1000px] border-l-black border-opacity-30  text-center">
          <ResumeCard className=''
            title="Software Engineer"
            subTitle="Adasa Systems"
            result="Aug 2022 - Nov 2022"
            des="Website Maintenance, Front-end web development, Back-end Web Support and development. Website Testing. Software development, DDevelop skills in understanding client’s requirements and the ability to build websites from
            client specifications"
          />
          {/* <ResumeCard
            title="AS - Science & Information"
            subTitle="SuperKing College (2001 - 2005)"
            result="4.75/5"
            des="Higher education is tertiary education leading to award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="Secondary School Education"
            subTitle="Kingstar Secondary School (1998 - 2000)"
            result="5.00/5"
            des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          /> */}
        </div>
      </div>
      {/* part Two */}

      {/* <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2010 - 2022</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Sr. Software Engineer"
            subTitle="Google Out Tech - (2017 - Present)"
            result="USA"
            des="Google's hiring process is an important part of our culture. Googlers care deeply about their teams and the people who make them up."
          />
          <ResumeCard
            title="Web Developer & Trainer"
            subTitle="Apple Developer Team - (2012 - 2016)"
            result="MALAYSIA"
            des="A popular destination with a growing number of highly qualified homegrown graduates, it's true that securing a role in Malaysia isn't easy."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="Nike - (2020 - 2011)"
            result="Oman"
            des="The Oman economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
          />
        </div> */}
      {/* </div> */}
      </div>
      </motion.div>
  )
}

export default Experience