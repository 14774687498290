export const navLinksdata =[
    {
        id:1001,
        title:"Home",
        link:"home"
    },
    {
        id:1002,
        title:"Features",
        link:"features"
    },
    {
        id:1003,
        title:"Projects",
        link:"projects"
    },
    {
        id:1004,
        title:"Resume",
        link:"resume"
    },
    // {
    //     id:1005,
    //     title:"Testimonials",
    //     link:"testimonials"
    // },
    {
        id:1006,
        title:"Contact",
        link:"contact"
    },
]