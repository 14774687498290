import Banner from "./components/banner/Banner";
import Contact from "./components/contact/Contact";
import Features from "./components/features/Features";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/projects/Projects";
import Resume from "./components/resume/Resume";
import Testimonials from "./components/testimonials/Testimonials";
import video from '../src/assets/3.mp4'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="w-full h-auto bg-bodyColor text-lightText px-4">
      <video className="video" autoPlay loop muted>
        <source src={video}/>
      </video>
      <div className="w-[93%] m-auto ">
        <Navbar/>
        <Banner/>
        <Features/>
        <Projects/>
        <Resume/>
        {/* <Testimonials/> */}
      <Contact/>
      </div>

    </div>
    </Router>
  );
}

export default App;
