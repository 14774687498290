import React from 'react'
import Title from '../layouts/Title'
import Education from './Education'
import Skills from './Skills'
import Experience from './Experience'
import { useState } from 'react'
import Achievements from './Achievements'


const Resume = () => {

  const [educationData, setEducationData] = useState(true);
  const [skillData, setSkillData] = useState(false);
  const [experienceData, setExperienceData] = useState(false);
  const [achievementData, setAchievementData] = useState(false); 
  return (
    <section id='resume' className='w-full py-20 border-b-[1px border-b-black] h-full sticky sm:max-h-100'>
      
    <div className='flex justify-center items-center text-center'>
    <Title
    title=''
    des='My Resume'/>
    </div>

    <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 text-center">
            <li onClick={() =>
              setEducationData(true) &
              setSkillData(false) &
              setExperienceData(false) &
              setAchievementData(false)
            } className={`${educationData ? "border-designColor rounded-lg" : "border-transparent"} resumeLi text-black`}>Education</li>

            <li onClick={() =>
              setEducationData(false) &
              setSkillData(true) &
              setExperienceData(false) &
              setAchievementData(false)
            } className={`${
              skillData ? "border-designColor rounded-lg" : "border-transparent"
            } resumeLi text-black`}>Professional Skills</li>

            <li onClick={() =>
              setEducationData(false) &
              setSkillData(false) &
              setExperienceData(true) &
              setAchievementData(false)
            } className={`${
              experienceData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi text-black`}>Experience</li>

            <li onClick={() =>
              setEducationData(false) &
              setSkillData(false) &
              setExperienceData(false) &
              setAchievementData(true)
            } className={`${
              achievementData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi text-black`}>Achievements</li>
        </ul>
    </div>

    {educationData && <Education />}
      {skillData && <Skills />}
      {achievementData && <Achievements />}
      {experienceData && <Experience />}
    
    
    </section>

  )
}

export default Resume