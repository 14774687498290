import React from 'react'
import Title from '../layouts/Title'
import Card from './Card'
import { FaReact,  } from 'react-icons/fa'
import { SiTrustpilot,} from 'react-icons/si'
import {FaBars,} from 'react-icons/fa'
import { MdTempleBuddhist } from 'react-icons/md'
import { GiDeadEye } from 'react-icons/gi'
import { TbBrandBandcamp } from 'react-icons/tb'


function Features() {
  return (
    <section id="features" className='w-full pb-20 border-b-[1px] border-b-black sticky'>
       
      
<div className='flex justify-center items-center text-center '>
<Title title="" des="I Specialise in"/>

</div>
      <div className='grid grid-col-1 md:grid-col-2 xl:grid-cols-3 gap-20'>
        <Card
        title="Responsive Websites"
        des='Websites that look and feel good on all devices!'
        icon={<FaReact/>}
        />
        <Card
        title="Redesigning Branding"
        des='Redesigning your brand as per current trends!'
        icon={<TbBrandBandcamp/>}
        />
        <Card
        title="Building Trust"
        des='Show empathy and understanding towards others.'
        icon={<SiTrustpilot/>}
        />
        <Card
        title="Ideas for a better world"
        des='Have an innovative idea in your mind? Lets chat!'
        icon={<GiDeadEye/>}
        />
        <Card
        title="Problem Solving"
        des='Can analyze complex problems, propose effective solutions, and implement them efficiently using software development best practices.'
        icon={<FaBars/>}
        />
        <Card
        title="Attention to Detail"
        des='Attention to detail, ensuring that the code is accurate, efficient, and meets the specified requirements.'
        icon={<MdTempleBuddhist/>}
        />
        
      </div>
    
    </section>

  )
}

export default Features