import React from 'react'
import Title from '../layouts/Title'
import ProjectCard from './ProjectCard'
import  { projectOne }  from '../../assets/'
import  {projectTwo} from '../../assets/'
import  {projectThree} from '../../assets/'
import { Link } from 'react-router-dom'


function Projects() {
  return (
    <section id='projects' className='w-full py-20 border-b-[1px border-b-black] sticky'>
      
        <div className='flex justify-center items-center text-center'>
        <Title
        title=''
        des='My Projects'/>
        </div>
    
        <div className="sm:grid md:grid-col-2 xl:grid-cols-3 gap-20">
          <Link to='https://sunnnyweather.netlify.app/'>
        <ProjectCard 
          title="Weather APP"
          des="Check weather of any city by simply typing city name."
          src={projectOne}
          />
          </Link>

          <Link to='https://add-daily-task.netlify.app'>
        <ProjectCard
          title="To-do list"
          des="Add daily task in your to-do app."
          src={projectTwo}
          />
          </Link>

          <Link to='https://play-tic-tac-toe-games.netlify.app'>
        <ProjectCard
          title="Tic-Tac-Toe"
          des="Play tic-tac-toe with your friend"
          src={projectThree}
          />
          </Link>
       
       
      </div>
    </section>
  )
}

export default Projects
// flex justify-between sm:flex sm:flex-col sm:gap-20 