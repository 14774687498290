import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div className='pt-20'>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-l text-designColor tracking-[4px] text-center">Feb 2018 - Nov 2021</p>
          <h2 className="text-3xl md:text-4xl font-bold text-center">Bachelor of Information Technology</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px]  border-l-black border-opacity-30 flex flex-col gap-10">
          
          <ResumeCard
            title="Software Design and Development"
            subTitle="Charles Sturt University"
            result="Credit"
            des="Key Learning area: Programming and Software Development, Database Management, Networks and Security, Web Development, Project Management"
          />
        
        </div>
      </div>
      {/* part Two */}

      <div className='pt-20'>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-l text-designColor tracking-[4px] text-center">Jan 2022 - August 2022 </p>
          <h2 className="text-3xl md:text-4xl font-bold text-center">Professional Year</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Information Technology"
            subTitle="Performance Education"
            result="Sydney"
            des="Key Subjects: Australian Business Culture and Environment, Achieving Career
            Success, Australian Workplace Skills and Professional Business Communication"
          />
          
        </div>
      </div>
    </motion.div>
  );
}

export default Education